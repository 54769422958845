import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Hero from "../components/hero"
import Boxes from "../components/boxes"
import CtaContact from "../components/cta-contact"
import Seo from "../components/seo"

const Logistics = ({ data }) => {

  const hero = {
    'textintro': '',
    'title': 'Cutting Edge industries: Logistics',
    'squares': '',
    'squaresmove': false,
  }

  const box1 = {
    'title': '',
    'content': [
      {
        'title': 'Customer',
        'content': 'Meet increasing customer demand and fulfill your client’s expectations on shipping information and delivery care.'
      },
      {
        'title': 'Inter-modal',
        'content': 'Ensure you keep costs low without losing quality. Managing cost pressure with an intermodal approach between your own fleet and third-party fleets.',
      },
      {
        'title': 'Frictionless',
        'content': 'Build a frictionless experience, with digital tools, to minimize the impact and complexity of both upstream and downstream flows in the supply chain – and quickly solve unforeseen incidents.',
      }
    ],
  }

  return (
    <Layout
      linksnavcolor='black'
    >
      <Seo
        title={data.site.siteMetadata.title}
        description="Meet increasing customer demand and fulfill your client’s expectations on shipping information and delivery care"
      />
      <Hero
        bg='linear-gradient(180deg, #ffceba 96%, #000000)'
        color='black'
        textintro={hero.textintro}
        title={hero.title}
        squares={hero.squares}
        squaresmove={hero.squaresmove}
      />
      <Boxes
        colorsquares='secondary'
        bg='linear-gradient(180deg, #000000, #1e1e1e)'
        title={box1.title}
        columns={box1.content.length}
        content={box1.content}
      />
      <CtaContact />
    </Layout>
  )
}

export default Logistics

export const query = graphql`
  query Logistics {
    site {
      siteMetadata {
        title
      }
    }
  }
`
